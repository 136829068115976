var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.errored && !_vm.loading)?_c('div',[_c('ul',_vm._l((_vm.transactions),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
            name: 'SpendingByCategories',
            params: {
              fromDate: new Date(item.item1),
              toDate: new Date(item.item2)
            }
          }}},[_vm._v(_vm._s(item.item1)+" — "+_vm._s(item.item2))])],1)}),0)]):_vm._e(),(!_vm.errored && _vm.loading)?_c('div',{staticClass:"alert alert-info"},[_vm._v("Loading...")]):_vm._e(),(_vm.errored)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" Error: "+_vm._s(this.error_text)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }