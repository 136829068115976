import { render, staticRenderFns } from "./NavPagination.vue?vue&type=template&id=bd2589d4&"
import script from "./NavPagination.vue?vue&type=script&lang=js&"
export * from "./NavPagination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports