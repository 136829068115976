<template>
  <div class="alert alert-info">
    <router-link
      :to="{
        name: 'CreateXchngBonusTransaction',
        params: { id: info.transaction.id }
      }"
      class="btn btn-outline-secondary"
      >Начислить бонусы</router-link
    >
    <router-link
      :to="{
        name: 'CorrectAmountPolishhubMoneyTransaction',
        params: { id: info.transaction.id }
      }"
      class="btn btn-outline-secondary"
      >Корректировка курса</router-link
    >
    <router-link
      :to="{
        name: 'BindPolishhubMoneyTransfer',
        params: { id: info.transaction.id }
      }"
      class="btn btn-outline-secondary"
      >Привязать участника закупки</router-link
    >
    <router-link
      :to="{
        name: 'BindPolishhubProviderTransaction',
        params: { id: info.transaction.id }
      }"
      class="btn btn-outline-secondary"
      >Привязать поставщика</router-link
    >
  </div>
</template>

<script>
export default {
  name: "TransactionDetailButtons",
  metaInfo() {
    return {
      title: `${this.$i18n.t("transaction.detail.title")} ${
        this.info.transaction.id
      }`
    };
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  }
};
</script>
