var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.info.categories.length > 0)?_c('div',[_c('table',{staticClass:"table table-bordered"},[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.headers.category")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.headers.amount")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.headers.comment")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.headers.actions")))])]),_vm._l((_vm.info.categories),function(category){return _c('tr',{key:category.id},[_c('td',[_vm._v(_vm._s(category.categoryTitle))]),_c('td',[_vm._v(_vm._s(category.amount))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.getComments(category.comment))}}),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'TransactionCategoryEdit',
                params: { id: category.id }
              }}},[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.action.edit")))]),_c('router-link',{attrs:{"to":{
                name: 'TransactionCategoryDelete',
                params: { id: category.id }
              }}},[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.action.delete")))])],1)])})],2)])]):_vm._e(),(_vm.hasCategorised(_vm.info))?_c('div',[_c('div',{staticClass:"alert alert-success"},[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.categories.balloon.success"))+" ")])]):_c('div',[_c('div',{staticClass:"alert alert-danger"},[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.categories.balloon.error"))+" ")])]),_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
      name: 'TransactionCategoryCreate',
      params: { id: _vm.info.transaction.id }
    }}},[_vm._v(_vm._s(_vm.$t("transaction.detail.categories.action.add")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }