<template>
  <div>
    <div v-if="!errored && !loading">
      <div class="table-responsive">
        <table class="table">
          <thead></thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";

export default {
  name: "StatByMonths",
  mixins: [BaseApiRequestMixin],
  data() {
    return {
      items: null
    };
  },
  mounted() {
    this.request("/Report/StatByMonths", {}, response => {
      this.items = response.data;
    });
  }
};
</script>
