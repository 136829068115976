const CleanMoneyMixin = {
  methods: {
    cleanMoney(e) {
      const target = e.target;
      const val = target.value;
      if (val === "") return;
      target.value = val
        .replace("руб", "")
        .replace("ру", "")
        .replace("р", "")
        .replace(" ", "")
        .replace(",", ".")
        .replace("<", ".")
        .replace("б", ".")
        .replace("Б", ".")
        .replace(">", ".")
        .replace("ю", ".")
        .replace("Ю", ".")
        .replace("?", ".")
        .replace("/", ".");
    }
  }
};

export default CleanMoneyMixin;
