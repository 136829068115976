<template>
  <div>
    <div v-if="!errored && !loading">
      <ul>
        <li v-for="(item, index) in transactions" :key="index">
          <router-link
            :to="{
              name: 'SpendingByCategories',
              params: {
                fromDate: new Date(item.item1),
                toDate: new Date(item.item2)
              }
            }"
            >{{ item.item1 }} — {{ item.item2 }}</router-link
          >
        </li>
      </ul>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";

export default {
  name: "StatByWeeks",
  mixins: [BaseApiRequestMixin],
  data() {
    return {
      transactions: null
    };
  },
  mounted() {
    this.request("/Report/StatByWeeks", {}, response => {
      this.transactions = response.data;
    });
  }
};
</script>
