<template>
  <div class="container">
    <div v-if="!errored && !loading">
      <div class="row">
        <div class="col-12">
          <h2>Новая транзакция с MoneyTransfer Polishhub</h2>

          <div class="row">
            <div class="col-sm-12">
              <form @submit="saveForm">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="created">Created</label>
                      <input
                        v-model="transaction.created"
                        id="created"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="amount">Amount</label>
                      <input
                        v-model="transaction.amount"
                        id="amount"
                        @keyup="cleanMoney"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="Checked">Checked</label>
                      <input
                        type="checkbox"
                        id="Checked"
                        v-model="transaction.checked"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="comment">Comment</label>
                      <input
                        v-model="transaction.comment"
                        id="comment"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="SourceTitle">Source</label>
                      <input
                        v-model="transaction.sourceTitle"
                        id="SourceTitle"
                        class="form-control"
                        readonly="readonly"
                      />
                    </div>
                    <div class="form-group">
                      <label for="DestinationTitle">Destination</label>
                      <input
                        v-model="transaction.destinationTitle"
                        id="DestinationTitle"
                        class="form-control"
                        readonly="readonly"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="SenderTitle">Sender</label>
                      <input
                        v-model="transaction.senderTitle"
                        id="SenderTitle"
                        class="form-control"
                        readonly="readonly"
                      />
                    </div>
                    <div class="form-group">
                      <label for="RecipientTitle">Recipient</label>
                      <input
                        v-model="transaction.recipientTitle"
                        id="RecipientTitle"
                        class="form-control"
                        readonly="readonly"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="submit"
                        value="Create"
                        class="w-100 btn btn-outline-secondary"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <search-account
                @set-source="onSource"
                @set-destination="onDestination"
              ></search-account>
            </div>
            <div class="col-sm-6">
              <search-polishhub-moneytransaction
                @set-sender="onSender"
                @set-recipient="onRecipient"
              ></search-polishhub-moneytransaction>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";
import CleanMoneyMixin from "@/mixins/CleanMoneyMixin";
import SearchAccount from "@/components/Search/Account";
import SearchPolishhubMoneytransaction from "@/components/Search/PolishhubMoneytransaction";

export default {
  name: "CreatePolishhubMoneyTransfer",
  metaInfo() {
    return {
      title: this.$i18n.t("transaction.create.title")
    };
  },
  components: {
    SearchAccount,
    SearchPolishhubMoneytransaction
  },
  mixins: [BaseApiRequestMixin, CleanMoneyMixin],
  data() {
    return {
      transaction: {
        id: 0,
        created: new Date().toISOString(),
        amount: 1,
        checked: false,
        comment: "",
        sourceId: 1,
        sourceTitle: "",
        destinationId: 1,
        destinationTitle: "",
        senderId: 1,
        senderTitle: "",
        recipientId: 1,
        recipientTitle: ""
      },
      transaction_default: {
        id: 0,
        created: new Date().toISOString(),
        amount: 100,
        checked: true,
        comment: "",
        sourceId: 10040,
        sourceTitle: "Контрагент по лакам POLISHHUB",
        destinationId: 1510,
        destinationTitle: "Сбер Андрей Visa Electron 1896",
        senderId: 238,
        senderTitle: "An unknown contact | Неизвестный плательщик",
        recipientId: 4,
        recipientTitle: "Aurora"
      }
    };
  },
  mounted() {
    this.transaction = Object.assign(
      this.transaction,
      this.transaction_default
    );
    if (typeof this.$route.query.json !== "undefined") {
      this.transaction = Object.assign(
        this.transaction,
        JSON.parse(this.$route.query.json)
      );
    }
  },
  methods: {
    saveForm() {
      this.request(
        "/Polishhub/CreateMoneyTransaction",
        { Transaction: this.transaction },
        result => {
          this.$router.push({
            name: "TransactionDetail",
            params: { id: result.data }
          });
          location.reload();
        }
      );
    },
    onSource(account) {
      this.transaction.sourceTitle = account.title;
      this.transaction.sourceId = account.id;
    },
    onDestination(account) {
      this.transaction.destinationTitle = account.title;
      this.transaction.destinationId = account.id;
    },
    onSender(account) {
      this.transaction.senderTitle = account.displayName;
      this.transaction.senderId = account.id;
    },
    onRecipient(account) {
      this.transaction.recipientTitle = account.displayName;
      this.transaction.recipientId = account.id;
    }
  }
};
</script>
