var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(!_vm.errored && !_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v(_vm._s(_vm.$t("transaction.detail.title"))+" #"+_vm._s(_vm.info.transaction.id))]),_c('transaction-detail-buttons',{attrs:{"info":this.info}}),_c('polishhub-transaction-detail-buttons',{attrs:{"info":this.info}}),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.created"))+": "+_vm._s(_vm._f("date")(_vm.info.transaction.created))+" ")]),(
            this.info.source.currencyId == this.info.destination.currencyId
          )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.amount"))+": "+_vm._s(_vm._f("money")(this.info.transaction.amountSource))+" "+_vm._s(this.info.source.currencyCode)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.amount"))+": "+_vm._s(_vm._f("money")(this.info.transaction.amountSource))+" "+_vm._s(this.info.source.currencyCode)+" -> "+_vm._s(_vm._f("money")(this.info.transaction.amountDestination))+" "+_vm._s(this.info.destination.currencyCode)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.checked"))+": "+_vm._s(_vm.info.transaction.checked)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.comment"))+": "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getComments(_vm.info.transaction.comment))}})]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.source"))+": "),_c('router-link',{attrs:{"to":{
              name: 'AccountIndex',
              params: { AccountId: _vm.info.source.id }
            }}},[_vm._v(_vm._s(_vm.info.source.title))])],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.destination"))+": "),_c('router-link',{attrs:{"to":{
              name: 'AccountIndex',
              params: { AccountId: this.info.destination.id }
            }}},[_vm._v(_vm._s(_vm.info.destination.title))])],1)]),_c('transaction-detail-categories',{attrs:{"info":this.info}})],1)]):_vm._e(),(!_vm.errored && _vm.loading)?_c('div',{staticClass:"alert alert-info"},[_vm._v(" "+_vm._s(_vm.$t("api.request.state.loading"))+"… ")]):_vm._e(),(_vm.errored)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" "+_vm._s(_vm.$t("api.request.state.error"))+": "+_vm._s(this.error_text)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }