const CommentHelper = {
  getComments(text) {
    let result = text;
    if (text == null || text === "") return result;

    result = result.replace(
      /ID:(\d+)P /g,
      '<a href="http://polishhub.ru/Office/MoneyTransfer/Details/$1">ID:$1P</a> '
    );
    result = result.replace(
      /ID:(\d+)PS /g,
      '<a href="http://polishhub.ru/Office/ProviderTransaction/Details/$1">ID:$1PS</a> '
    );
    result = result.replace(
      /ID:(\d+)C /g,
      '<a href="https://copylock.metainfo.ru/Transaction/Detail/$1">ID:$1C</a> '
    );

    return result;
  }
};

export default CommentHelper;
