<template>
  <div>
    <div>created: {{ info.transaction.created | date }}</div>
    <div>
      {{ $t("transaction.detail.info.source") }}:
      <router-link
        :to="{
          name: 'AccountIndex',
          params: { AccountId: info.source.id }
        }"
        >{{ info.source.title }}</router-link
      >
      {{ info.transaction.amountSource | money }}
      {{ info.source.currencyCode }}
    </div>
    <div>
      {{ $t("transaction.detail.info.destination") }}:
      <router-link
        :to="{
          name: 'AccountIndex',
          params: {
            AccountId: info.destination.id
          }
        }"
        >{{ info.destination.title }}</router-link
      >
      {{ info.transaction.amountDestination | money }}
      {{ info.destination.currencyCode }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionSummary",
  props: {
    info: {
      type: Object,
      required: true
    }
  }
};
</script>
