var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"NeedlePhMt"}},[_vm._v("Поиск контакта-отправителя и контакта-получателя:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.needle),expression:"needle"}],staticClass:"form-control",attrs:{"id":"NeedlePhMt"},domProps:{"value":(_vm.needle)},on:{"keyup":_vm.searchNeedlePhMt,"input":function($event){if($event.target.composing)return;_vm.needle=$event.target.value}}})])])]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"resultsPhMt"}},[(!_vm.errored && !_vm.loading)?_c('div',_vm._l((_vm.items),function(item){return _c('div',{key:item.id},[_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"href":`http://polishhub.ru/Office/Contact/Summary/${item.id}`}},[_vm._v(_vm._s(item.displayName))]),_c('button',{staticClass:"btn btn-outline-secondary",on:{"click":function($event){return _vm.$emit('set-sender', {
                id: item.id,
                displayName: item.displayName
              })}}},[_vm._v(" отправитель ")]),_c('button',{staticClass:"btn btn-outline-secondary",on:{"click":function($event){return _vm.$emit('set-recipient', {
                id: item.id,
                displayName: item.displayName
              })}}},[_vm._v(" получатель ")]),_c('pre',[_vm._v(_vm._s(item.notes))])])}),0):_vm._e(),(!_vm.errored && _vm.loading)?_c('div',{staticClass:"alert alert-info"},[_vm._v(" Loading... ")]):_vm._e(),(_vm.errored)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" Error: "+_vm._s(this.error_text)+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }