var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-info"},[_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
      name: 'CreateXchngBonusTransaction',
      params: { id: _vm.info.transaction.id }
    }}},[_vm._v("Начислить бонусы")]),_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
      name: 'CorrectAmountPolishhubMoneyTransaction',
      params: { id: _vm.info.transaction.id }
    }}},[_vm._v("Корректировка курса")]),_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
      name: 'BindPolishhubMoneyTransfer',
      params: { id: _vm.info.transaction.id }
    }}},[_vm._v("Привязать участника закупки")]),_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
      name: 'BindPolishhubProviderTransaction',
      params: { id: _vm.info.transaction.id }
    }}},[_vm._v("Привязать поставщика")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }