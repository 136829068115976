<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>SpendingByCategories</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          <form @submit="executeRequest" class="form-inline">
            <label for="fromDate" class="mx-2"
              >from: {{ this.fromDate.toISOString() }}</label
            >
            <input
              type="datetime-local"
              id="fromDate"
              :v-model="this.fromDate"
              class="form-control mx-2"
            />
            <label for="toDate" class="mx-2"
              >to {{ this.toDate.toISOString() }}</label
            >
            <input
              type="datetime-local"
              id="toDate"
              :value="this.toDate && this.toDate.toISOString().split('T')[0]"
              @input="this.toDate = $event.target.valueAsDate"
              class="form-control mx-2"
            />
            <button type="submit" class="btn btn-secondary mx-2">фильтр</button>
          </form>
        </div>
      </div>
    </div>
    <div class="row" v-if="!errored && !loading">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <td>category</td>
              <td class="text-right">expense</td>
              <td class="text-right">income</td>
              <td class="text-right">amount</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <router-link
                  :to="{
                    name: 'TransactionsSearch',
                    params: {
                      fromDate: fromDate,
                      toDate: toDate,
                      categoryId: item.categoryId
                    }
                  }"
                  >{{ item.categoryTitle }}</router-link
                >
              </td>
              <td class="text-right">{{ item.expense | money }}</td>
              <td class="text-right">{{ item.income | money }}</td>
              <td class="text-right">{{ item.amount | money }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="!errored && loading">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="alert alert-info">Loading...</div>
      </div>
    </div>
    <div class="row" v-if="errored">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="alert alert-danger">Error: {{ this.error_text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";

export default {
  name: "SpendingByCategories",
  mixins: [BaseApiRequestMixin],
  props: {
    fromDate: {
      type: Date,
      required: false,
      default: null
    },
    toDate: {
      type: Date,
      required: false,
      default: null
    }
  },
  data() {
    return {
      items: null
    };
  },
  methods: {
    executeRequest() {
      this.request("/Report/SpendingByCategories", this.$props, response => {
        this.items = response.data;
      });
    }
  },
  mounted() {
    this.executeRequest();
  }
};
</script>
