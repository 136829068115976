<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>{{ $t("transaction.create.title") }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-2">
        <router-link to="/Transaction/Create" class="btn btn-info">{{
          $t("transaction.create.title")
        }}</router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-2">
        <router-link
          to="/Transaction/CreatePolishhubMoneyTransfer"
          class="btn btn-info"
          >MoneyTransfer Polishhub</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-2">
        <router-link
          to="/Transaction/CreatePolishhubProviderTransaction"
          class="btn btn-info"
          >ProviderTransaction Polishhub</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionCreatorAssistant",
  metaInfo() {
    return {
      title: this.$i18n.t("creatorAssistant.title")
    };
  }
};
</script>
