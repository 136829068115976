var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(!_vm.errored && !_vm.loading)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v(_vm._s(_vm.$t("transaction.search.title")))])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-info"},[_c('form',{staticClass:"form-inline",on:{"submit":_vm.executeRequest}},[_c('label',{staticClass:"mx-2",attrs:{"for":"fromDate"}},[_vm._v(_vm._s(_vm.$t("transaction.search.filter.from"))+": "+_vm._s(this.fromDate.toISOString()))]),_c('label',{staticClass:"mx-2",attrs:{"for":"toDate"}},[_vm._v(_vm._s(_vm.$t("transaction.search.filter.to"))+" "+_vm._s(this.toDate.toISOString()))]),_c('button',{staticClass:"btn btn-secondary mx-2",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("transaction.search.filter.button.submit"))+" ")])])])]),_c('div',{staticClass:"col-md-4"},[_c('table',{staticClass:"table table-bordered table-sm"},[_c('thead',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.created")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.source")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.destination")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.categories")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.checked")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.amount")))]),_c('td',[_vm._v(_vm._s(_vm.$t("transaction.search.headers.comments")))])])]),_c('tbody',_vm._l((_vm.transactions),function(item){return _c('tr',{key:item.id},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'TransactionDetail', params: { id: item.id } }}},[_vm._v(_vm._s(_vm._f("date")(item.created)))])],1),_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'AccountIndex',
                    params: { AccountId: item.sourceId }
                  }}},[_vm._v(_vm._s(item.sourceTitle))])],1),_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'AccountIndex',
                    params: { AccountId: item.destinationId }
                  }}},[_vm._v(_vm._s(item.destinationTitle))])],1),_c('td',[_c('transaction-category-list',{attrs:{"transactions":item.categories}})],1),_c('td',[_vm._v(_vm._s(item.checked ? "Y" : "N"))]),_c('td',[_vm._v(_vm._s(item.amount))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.getComments(item))}})])}),0)])])])]):_vm._e(),(!_vm.errored && _vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"alert alert-info"},[_vm._v(" "+_vm._s(_vm.$t("api.request.state.loading"))+"… ")])])]):_vm._e(),(_vm.errored)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"alert alert-danger"},[_vm._v(" "+_vm._s(_vm.$t("api.request.state.error"))+": "+_vm._s(this.error_text)+" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }