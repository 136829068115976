<template>
  <div>
    <div v-if="!errored && !loading">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>Создание транзакции</h2>
          </div>
          <div class="col-md-4">
            <form @submit="saveForm">
              <div class="form-group">
                <label for="created">Created</label>
                <input
                  v-model="transaction.created"
                  id="created"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="amount">Amount</label>
                <input
                  v-model="transaction.amount"
                  id="amount"
                  @keyup="cleanMoney"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="Checked">Checked</label>
                <input
                  type="checkbox"
                  id="Checked"
                  v-model="transaction.checked"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="comment">Comment</label>
                <input
                  v-model="transaction.comment"
                  id="comment"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="SourceTitle">Source</label>
                <input
                  v-model="transaction.sourceTitle"
                  id="SourceTitle"
                  class="form-control"
                  readonly="readonly"
                />
              </div>
              <div class="form-group">
                <label for="DestinationTitle">Destination</label>
                <input
                  v-model="transaction.destinationTitle"
                  id="DestinationTitle"
                  class="form-control"
                  readonly="readonly"
                />
              </div>
              <div class="form-group">
                <input
                  type="submit"
                  value="Save"
                  class="btn btn-outline-secondary"
                />
              </div>
            </form>
          </div>
        </div>
        <search-account
          @set-source="onSource"
          @set-destination="onDestination"
        ></search-account>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";
import CleanMoneyMixin from "@/mixins/CleanMoneyMixin";
import SearchAccount from "@/components/Search/Account";

export default {
  name: "CreateXchngBonusTransaction",
  metaInfo() {
    return {
      title: this.$i18n.t("transaction.create.title")
    };
  },
  components: {
    SearchAccount
  },
  mixins: [BaseApiRequestMixin, CleanMoneyMixin],
  props: {
    id: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      info: {
        transaction: {
          amount: 0
        }
      },
      loading: true,
      errored: false,
      transaction: {
        id: 0,
        transactionId: this.id,
        created: new Date().toISOString(),
        amount: 0,
        checked: true,
        comment: `ID:${this.id}C за букву с`,
        sourceId: 1510,
        sourceTitle: "Visa3411 Пух-карта",
        destinationId: 10149,
        destinationTitle: "Поебень счёт"
      }
    };
  },
  mounted() {
    this.startComponent();
  },
  methods: {
    startComponent() {
      this.request(
        "/Transaction/Detail",
        { TransactionId: this.id },
        response => {
          this.info = response.data;
          this.transaction.amount = this.info.transaction.amount;
        }
      );
    },
    saveForm() {
      this.request(
        "/Polishhub/CreateXchngBonusTransaction",
        { Vm: this.transaction },
        result => {
          this.$router.push({
            name: "TransactionDetail",
            params: { id: result.data }
          });
          location.reload();
        }
      );
    },
    onSource(account) {
      this.transaction.sourceTitle = account.title;
      this.transaction.sourceId = account.id;
    },
    onDestination(account) {
      this.transaction.destinationTitle = account.title;
      this.transaction.destinationId = account.id;
    }
  }
};
</script>
