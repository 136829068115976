<template>
  <div>
    <div v-if="!errored && !loading">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-8">
            <h2>Привязать участника закупки полишхаб</h2>
            <transaction-summary :info="this.info"></transaction-summary>
            <router-link
              :to="{
                name: 'TransactionDetail',
                params: { id: this.id }
              }"
              >Назад в транзакцию</router-link
            >
            <hr />
            <form @submit="saveForm">
              <div class="form-group">
                <label for="SenderTitle">Sender</label>
                <input
                  v-model="data.senderTitle"
                  id="SenderTitle"
                  class="form-control"
                  readonly="readonly"
                />
              </div>
              <div class="form-group">
                <label for="RecipientTitle">Recipient</label>
                <input
                  v-model="data.recipientTitle"
                  id="RecipientTitle"
                  class="form-control"
                  readonly="readonly"
                />
              </div>
              <div class="form-group">
                <input
                  type="submit"
                  value="Привязать"
                  class="w-100 btn btn-outline-secondary"
                />
              </div>
            </form>
            <search-polishhub-moneytransaction
              @set-sender="onSender"
              @set-recipient="onRecipient"
            ></search-polishhub-moneytransaction>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import TransactionSummary from "@/components/Polishhub/TransactionSummary";
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";
import CleanMoneyMixin from "@/mixins/CleanMoneyMixin";
import SearchPolishhubMoneytransaction from "@/components/Search/PolishhubMoneytransaction";

export default {
  name: "BindMoneyTransfer",
  metaInfo() {
    return {
      title: this.$i18n.t("transaction.create.title")
    };
  },
  components: {
    SearchPolishhubMoneytransaction,
    TransactionSummary
  },
  mixins: [BaseApiRequestMixin, CleanMoneyMixin],
  props: {
    id: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      info: {},
      data: {
        id: 0,
        senderId: 238,
        senderTitle: "An unknown contact | Неизвестный плательщик",
        recipientId: 4,
        recipientTitle: "Aurora"
      }
    };
  },
  methods: {
    saveForm() {
      this.request(
        "/Polishhub/BindMoneyTransaction",
        { Transaction: this.data },
        () => {
          this.$router.push({
            name: "TransactionDetail",
            params: { id: this.info.transaction.id }
          });
          location.reload();
        }
      );
    },
    onSender(account) {
      this.data.senderTitle = account.displayName;
      this.data.senderId = account.id;
    },
    onRecipient(account) {
      this.data.recipientTitle = account.displayName;
      this.data.recipientId = account.id;
    }
  },
  mounted() {
    this.request(
      "/Transaction/Detail",
      { TransactionId: this.id },
      response => {
        this.info = response.data;
        this.data.id = this.info.transaction.id;
      }
    );
  }
};
</script>
