<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>{{ $t("reports.index.title") }}</h1>
        <h2>SpendingByCategories - monthly</h2>
        <stat-by-months></stat-by-months>
        <h2>SpendingByCategories - weekly</h2>
        <stat-by-weeks></stat-by-weeks>
      </div>
    </div>
  </div>
</template>

<script>
import StatByMonths from "@/views/Reports/StatByMonths";
import StatByWeeks from "@/views/Reports/StatByWeeks";

export default {
  name: "ReportsIndex",
  metaInfo() {
    return {
      title: this.$i18n.t("reports.index.title")
    };
  },
  components: {
    StatByMonths,
    StatByWeeks
  }
};
</script>
