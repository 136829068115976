<template>
  <div>
    <div v-if="!errored && !loading">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>Новая транзакция с ProviderTransaction Polishhub</h2>

            <div class="row">
              <div class="col-sm-12">
                <form @submit="saveForm">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="created">Created</label>
                        <input
                          v-model="transaction.created"
                          id="created"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group">
                        <label for="amount">Amount</label>
                        <input
                          v-model="transaction.amount"
                          id="amount"
                          @keyup="cleanMoney"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group">
                        <label for="Checked">Checked</label>
                        <input
                          type="checkbox"
                          id="Checked"
                          v-model="transaction.checked"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group">
                        <label for="comment">Comment</label>
                        <input
                          v-model="transaction.comment"
                          id="comment"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="SourceTitle">Source</label>
                        <input
                          v-model="transaction.sourceTitle"
                          id="SourceTitle"
                          class="form-control"
                          readonly="readonly"
                        />
                      </div>
                      <div class="form-group">
                        <label for="DestinationTitle">Destination</label>
                        <input
                          v-model="transaction.destinationTitle"
                          id="DestinationTitle"
                          class="form-control"
                          readonly="readonly"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="currency">Currency</label>
                        <select
                          v-model="transaction.currency"
                          id="currency"
                          class="form-control"
                        >
                          <option
                            v-for="item in currencies"
                            :key="item.id"
                            :value="item.name"
                            >{{ item.name }}</option
                          >
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="AmountInCurrency">AmountInCurrency</label>
                        <input
                          v-model="transaction.amountInCurrency"
                          @keyup="cleanMoney"
                          id="AmountInCurrency"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group">
                        <label for="groupBuyingId">GroupBuying</label>
                        <select
                          v-model="transaction.groupBuyingId"
                          id="groupBuyingId"
                          class="form-control"
                        >
                          <option
                            v-for="item in groupbuyings"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.title }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          value="Create"
                          class="w-100 btn btn-outline-secondary"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <search-account
                  @set-source="onSource"
                  @set-destination="onDestination"
                ></search-account>
              </div>
              <div class="col-sm-6">
                <search-polishhub-providertransaction
                  @choose-gb="chooseGb"
                  @filter-gb="filterGb"
                ></search-polishhub-providertransaction>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";
import CleanMoneyMixin from "@/mixins/CleanMoneyMixin";
import SearchAccount from "@/components/Search/Account";
import SearchPolishhubProvidertransaction from "@/components/Search/PolishhubProvidertransaction";

export default {
  name: "CreatePolishhubProviderTransaction",
  metaInfo() {
    return {
      title: this.$i18n.t("transaction.create.title")
    };
  },
  components: {
    SearchAccount,
    SearchPolishhubProvidertransaction
  },
  mixins: [BaseApiRequestMixin, CleanMoneyMixin],
  data() {
    return {
      transaction: {
        id: 0,
        created: new Date().toISOString(),
        amount: 0,
        checked: false,
        comment: "",
        sourceId: 1520,
        sourceTitle: "Сбер Андрей Visa Gold 6961",
        destinationId: 10139,
        destinationTitle: "PAYPAL HYPNOTICPOL",
        currency: "USD",
        amountInCurrency: 200,
        groupBuyingId: 100
      },
      currencies: null,
      groupbuyings: null
    };
  },
  methods: {
    saveForm() {
      this.request(
        "/Polishhub/CreateProviderTransaction",
        { Transaction: this.transaction },
        result => {
          this.$router.push({
            name: "TransactionDetail",
            params: { id: result.data }
          });
          location.reload();
        }
      );
    },
    onSource(account) {
      this.transaction.sourceTitle = account.title;
      this.transaction.sourceId = account.id;
    },
    onDestination(account) {
      this.transaction.destinationTitle = account.title;
      this.transaction.destinationId = account.id;
    },
    chooseGb(id) {
      this.transaction.groupBuyingId = id;
    },
    filterGb(needle) {
      const regex = new RegExp(needle);
      const opts = document
        .querySelector("#groupBuyingId")
        .getElementsByTagName("option");

      for (let i = 0; i < opts.length; i += 1) {
        if (opts[i].text.match(regex)) {
          opts[i].style.display = "block";
        } else {
          opts[i].style.display = "none";
        }
      }
    }
  },
  mounted() {
    Promise.all([
      this.request(
        "http://polishhub.ru/Api/Copylock/Currency/List",
        {},
        response => {
          this.currencies = response.data;
        }
      ),
      this.request(
        "http://polishhub.ru/Api/Copylock/GroupBuying/MyGroupBuyingsAsManager",
        {},
        response => {
          this.groupbuyings = response.data;
        }
      )
    ]);
  }
};
</script>
