<template>
  <div>
    <div v-if="!errored && !loading">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-8">
            <h2>Привязать транзакцию поставщика полишхаб</h2>
            <transaction-summary :info="this.info"></transaction-summary>
            <router-link
              :to="{
                name: 'TransactionDetail',
                params: { id: this.id }
              }"
              >Назад в транзакцию</router-link
            >
            <hr />
            <form @submit="saveForm">
              <div class="form-group">
                <label for="groupBuyingId">GroupBuying</label>
                <select
                  v-model="data.groupBuyingId"
                  id="groupBuyingId"
                  class="form-control"
                >
                  <option
                    v-for="item in groupbuyings"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.title }}</option
                  >
                </select>
              </div>
              <div class="form-group">
                <input
                  type="submit"
                  value="Привязать"
                  class="w-100 btn btn-outline-secondary"
                />
              </div>
              <search-polishhub-providertransaction
                @choose-gb="chooseGb"
                @filter-gb="filterGb"
              ></search-polishhub-providertransaction>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import TransactionSummary from "@/components/Polishhub/TransactionSummary";
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";
import CleanMoneyMixin from "@/mixins/CleanMoneyMixin";
import SearchPolishhubProvidertransaction from "@/components/Search/PolishhubProvidertransaction";

export default {
  name: "BindPolishhubProviderTransaction",
  metaInfo() {
    return {
      title: this.$i18n.t("transaction.create.title")
    };
  },
  components: {
    SearchPolishhubProvidertransaction,
    TransactionSummary
  },
  mixins: [BaseApiRequestMixin, CleanMoneyMixin],
  props: {
    id: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      info: {},
      data: {
        id: 0,
        groupBuyingId: 100
      },
      groupbuyings: []
    };
  },
  methods: {
    saveForm() {
      this.request(
        "/Polishhub/BindProviderTransaction",
        { Transaction: this.data },
        () => {
          this.$router.push({
            name: "TransactionDetail",
            params: { id: this.info.transaction.id }
          });
          location.reload();
        }
      );
    },
    chooseGb(id) {
      this.data.groupBuyingId = id;
    },
    filterGb(needle) {
      const regex = new RegExp(needle);
      const opts = document
        .querySelector("#groupBuyingId")
        .getElementsByTagName("option");

      for (let i = 0; i < opts.length; i += 1) {
        if (opts[i].text.match(regex)) {
          opts[i].style.display = "block";
        } else {
          opts[i].style.display = "none";
        }
      }
    }
  },
  mounted() {
    Promise.all([
      this.request(
        "/Transaction/Detail",
        { TransactionId: this.id },
        response => {
          this.info = response.data;
          this.data.id = this.info.transaction.id;
        }
      ),
      this.request(
        "http://polishhub.ru/Api/Copylock/GroupBuying/MyGroupBuyingsAsManager",
        {},
        response => {
          this.groupbuyings = response.data;
        }
      )
    ]);
  }
};
</script>
