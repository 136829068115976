var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-info"},[_c('form',{staticClass:"form-inline",on:{"submit":_vm.executeRequest}},[_c('label',{staticClass:"mx-2",attrs:{"for":"fromDate"}},[_vm._v("from: "+_vm._s(this.fromDate.toISOString()))]),_c('input',{staticClass:"form-control mx-2",attrs:{"type":"datetime-local","id":"fromDate","v-model":this.fromDate}}),_c('label',{staticClass:"mx-2",attrs:{"for":"toDate"}},[_vm._v("to "+_vm._s(this.toDate.toISOString()))]),_c('input',{staticClass:"form-control mx-2",attrs:{"type":"datetime-local","id":"toDate"},domProps:{"value":this.toDate && this.toDate.toISOString().split('T')[0]},on:{"input":function($event){this.toDate = $event.target.valueAsDate}}}),_c('button',{staticClass:"btn btn-secondary mx-2",attrs:{"type":"submit"}},[_vm._v("фильтр")])])])])]),(!_vm.errored && !_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'TransactionsSearch',
                  params: {
                    fromDate: _vm.fromDate,
                    toDate: _vm.toDate,
                    categoryId: item.categoryId
                  }
                }}},[_vm._v(_vm._s(item.categoryTitle))])],1),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.expense)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.income)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.amount)))])])}),0)])])]):_vm._e(),(!_vm.errored && _vm.loading)?_c('div',{staticClass:"row"},[_vm._m(2)]):_vm._e(),(_vm.errored)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"alert alert-danger"},[_vm._v("Error: "+_vm._s(this.error_text))])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v("SpendingByCategories")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("category")]),_c('td',{staticClass:"text-right"},[_vm._v("expense")]),_c('td',{staticClass:"text-right"},[_vm._v("income")]),_c('td',{staticClass:"text-right"},[_vm._v("amount")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"alert alert-info"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }