<template>
  <div class="row">
    <div class="col-12">
      <form>
        <div class="form-group">
          <label for="NeedlePolishhubGb">Фильтр закупок:</label>
          <input
            id="NeedlePolishhubGb"
            name="NeedlePolishhubGb"
            class="form-control"
            v-model="needleGb"
            @keyup="searchNeedleGb"
          />
        </div>
      </form>
    </div>
    <div class="clearfix"></div>
    <div class="col-12">
      <div id="resultsPolishhubGb"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchPolishhubProvidertransaction",
  data() {
    return {
      needleGb: ""
    };
  },
  methods: {
    searchNeedleGb() {
      const data = this.getGbId(this.needleGb);

      if (data === null) {
        this.$emit("choose-gb", this.needleGb);
      } else {
        this.$emit("choose-gb", data);
      }
    },
    getGbId(needle) {
      const match = needle.match(
        /(http|https):\/\/polishhub.ru\/Office\/GroupBuying\/(Summary|Details|ProviderParcels|Overview)\?groupBuyingId=(\d+)/i
      );

      if (match) {
        return match[3];
      }

      const quickUrlmatch = needle.match(
        /(http|https):\/\/polishhub.ru\/z\/(\d+)/i
      );

      if (quickUrlmatch) {
        return quickUrlmatch[1];
      }

      return null;
    }
  }
};
</script>
