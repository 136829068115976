<template>
  <div class="row">
    <div class="col-12">
      <form>
        <div class="form-group">
          <label for="NeedlePhMt"
            >Поиск контакта-отправителя и контакта-получателя:</label
          >
          <input
            id="NeedlePhMt"
            v-model="needle"
            class="form-control"
            @keyup="searchNeedlePhMt"
          />
        </div>
      </form>
    </div>
    <div class="clearfix"></div>
    <div class="col-12">
      <div id="resultsPhMt">
        <div v-if="!errored && !loading">
          <div v-for="item in items" :key="item.id">
            <a
              :href="
                `http://polishhub.ru/Office/Contact/Summary/${item.id}`
              "
              class="btn btn-outline-secondary"
              >{{ item.displayName }}</a
            >
            <button
              @click="
                $emit('set-sender', {
                  id: item.id,
                  displayName: item.displayName
                })
              "
              class="btn btn-outline-secondary"
            >
              отправитель
            </button>
            <button
              @click="
                $emit('set-recipient', {
                  id: item.id,
                  displayName: item.displayName
                })
              "
              class="btn btn-outline-secondary"
            >
              получатель
            </button>
            <pre>{{ item.notes }}</pre>
          </div>
        </div>
        <div v-if="!errored && loading" class="alert alert-info">
          Loading...
        </div>
        <div v-if="errored" class="alert alert-danger">
          Error: {{ this.error_text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";

export default {
  name: "SearchPolishhubMoneytransaction",
  mixins: [BaseApiRequestMixin],
  data() {
    return {
      items: null,
      needle: ""
    };
  },
  methods: {
    searchNeedlePhMt() {
      this.request(
        "http://polishhub.ru/Api/Copylock/Contact/Search",
        { Needle: this.needle },
        result => {
          this.items = result.data;
        }
      );
    }
  }
};
</script>

<style scoped>
div#resultsPhMt pre {
  padding: 9.5px;
  margin: 0 0 10px;
  margin-top: 10px;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.42857143;
}

div#resultsPhMt .btn {
  margin-right: 10px;
}
</style>
