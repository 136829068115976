var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("created: "+_vm._s(_vm._f("date")(_vm.info.transaction.created)))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.source"))+": "),_c('router-link',{attrs:{"to":{
        name: 'AccountIndex',
        params: { AccountId: _vm.info.source.id }
      }}},[_vm._v(_vm._s(_vm.info.source.title))]),_vm._v(" "+_vm._s(_vm._f("money")(_vm.info.transaction.amountSource))+" "+_vm._s(_vm.info.source.currencyCode)+" ")],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t("transaction.detail.info.destination"))+": "),_c('router-link',{attrs:{"to":{
        name: 'AccountIndex',
        params: {
          AccountId: _vm.info.destination.id
        }
      }}},[_vm._v(_vm._s(_vm.info.destination.title))]),_vm._v(" "+_vm._s(_vm._f("money")(_vm.info.transaction.amountDestination))+" "+_vm._s(_vm.info.destination.currencyCode)+" ")],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }