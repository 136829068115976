<template>
  <div>
    <div v-if="!errored && !loading">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>Корректировка курса транзакции полишхаб</h2>
            <router-link
              :to="{
                name: 'TransactionDetail',
                params: { id: this.id }
              }"
              >Назад в транзакцию</router-link
            >
          </div>
          <div class="col-md-4">
            <form @submit="saveForm">
              <div class="form-group">
                <label for="newAmount">New amount</label>
                <input
                  v-model="transaction.newAmount"
                  id="newAmount"
                  @keyup="cleanMoney"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="num">Num</label>
                <input
                  v-model="transaction.num"
                  id="num"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <input
                  type="submit"
                  value="Save"
                  class="btn btn-outline-secondary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!errored && loading" class="alert alert-info">Loading...</div>
    <div v-if="errored" class="alert alert-danger">
      Error: {{ this.error_text }}
    </div>
  </div>
</template>

<script>
import BaseApiRequestMixin from "@/mixins/BaseApiRequestMixin";
import CleanMoneyMixin from "@/mixins/CleanMoneyMixin";

export default {
  name: "CorrectAmountPolishhubMoneyTransaction",
  metaInfo() {
    return {
      title: this.$i18n.t("transaction.create.title")
    };
  },
  mixins: [BaseApiRequestMixin, CleanMoneyMixin],
  props: {
    id: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      info: {
        transaction: {
          amount: 0
        }
      },
      loading: true,
      errored: false,
      transaction: {
        id: this.id,
        num: "",
        newAmount: 0
      }
    };
  },
  mounted() {
    this.startComponent();
  },
  methods: {
    startComponent() {
      this.request(
        "/Transaction/Detail",
        { TransactionId: this.id },
        response => {
          this.info = response.data;
          this.transaction.amount = this.info.transaction.amount;
          this.transaction.newAmount = this.info.transaction.amountSource;
        }
      );
    },
    saveForm() {
      this.request(
        "/Polishhub/CorrectAmountMoneyTransaction",
        { Vm: this.transaction },
        () => {
          this.$router.push({
            name: "TransactionDetail",
            params: { id: this.id }
          });
          location.reload();
        }
      );
    },
    onSource(account) {
      this.transaction.sourceTitle = account.title;
      this.transaction.sourceId = account.id;
    },
    onDestination(account) {
      this.transaction.destinationTitle = account.title;
      this.transaction.destinationId = account.id;
    }
  }
};
</script>
